import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

import {
  SessionStatus,
  setTargetCurrency,
  setSession,
  setSessionStatus,
  setClientName,
  setCustomerName,
  setAmountRules,
  setSourceCurrency,
} from './session';

import { setLanguage, updateSiteTheme } from '../config/config';
import { Themes } from '#/types';
import langConfig, { Languages } from '#/config/language/langConfig';

import { AsyncThunkApi } from '#/store/store';
import { throttle } from '#/util';

interface SessionPayload {
  id: string;
  theme: string | null;
  language: string | null;
  targetCurrency: string;
  clientName: string;
  customerFirstName: string;
  customerLastName: string;
  minAmount: number;
  maxAmount: number;
  sourceCurrency: string;
  availableAmount?: number;
}

export const initSession: AsyncThunk<void, SessionPayload, AsyncThunkApi> = createAsyncThunk(
  'session/init',
  throttle(
    async (
      {
        id,
        theme,
        language,
        targetCurrency,
        sourceCurrency,
        customerFirstName,
        customerLastName,
        clientName,
        minAmount,
        maxAmount,
        availableAmount,
      }: SessionPayload,
      { dispatch, extra }: AsyncThunkApi
    ) => {
      if (!id) {
        dispatch(setSessionStatus(SessionStatus.Invalid));

        return;
      }
      const currentTheme = theme || Themes.Light;
      const currentLanguage = language || langConfig.default;

      const { epay } = extra;
      epay.currentCurrency = targetCurrency;
      epay.session = id;

      dispatch(updateSiteTheme(currentTheme as Themes));
      dispatch(setLanguage(currentLanguage as Languages));
      dispatch(setSession(id));
      dispatch(setTargetCurrency(targetCurrency));
      dispatch(setClientName(clientName));
      dispatch(setCustomerName({ firstName: customerFirstName, lastName: customerLastName }));
      dispatch(setAmountRules({ min: minAmount, max: maxAmount, availableAmount }));
      dispatch(setSourceCurrency(sourceCurrency));
    }, 500)
);
