import React, { useEffect, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import usePostMessage from '#/hooks/epay/usePostMessage';
import { useAppDispatch } from '#/hooks';

import { SessionStatus, selectSessionStatus } from '#/store/reducers/session/session';
import { initSession } from '#/store/reducers/session/thunk';

import Loader from '#/elements/other/loader/Loader';
import Title from '#/elements/text/title/Title';
import { UrlSearchParamsEnum } from '#/types';

interface Props {
  children: ReactElement<any, any>;
}

const SessionProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const [params] = useSearchParams();

  const dispatch = useAppDispatch();
  const status: SessionStatus = useSelector(selectSessionStatus);

  const sessionId: string | null = params.get(UrlSearchParamsEnum.SessionId);
  const targetCurrency: string | null = params.get(UrlSearchParamsEnum.SelectedCurrency);
  const sourceCurrency: string | null = params.get(UrlSearchParamsEnum.SourceCurrency);
  const theme: string | null = params.get(UrlSearchParamsEnum.Theme);
  const language: string | null = params.get(UrlSearchParamsEnum.Language);
  const customerFirstName: string | null = params.get(UrlSearchParamsEnum.CustomerFirstName);
  const customerLastName: string | null = params.get(UrlSearchParamsEnum.CustomerLastName);
  const clientName: string | null = params.get(UrlSearchParamsEnum.ClientName);
  const minAmount: string | null = params.get(UrlSearchParamsEnum.MinAmount);
  const maxAmount: string | null = params.get(UrlSearchParamsEnum.MaxAmount);
  const availableAmount: string | null = params.get(UrlSearchParamsEnum.AvailableAmount);

  usePostMessage();

  useEffect(() => {
    dispatch(initSession({
      id: sessionId || '',
      targetCurrency: targetCurrency || '',
      theme,
      language,
      customerFirstName: customerFirstName || '',
      customerLastName: customerLastName || '',
      clientName : clientName || '',
      minAmount: Number(minAmount),
      maxAmount: Number(maxAmount),
      sourceCurrency: sourceCurrency || '',
      availableAmount: availableAmount ? Number(availableAmount) : undefined,
    }));
  }, [dispatch, sessionId, theme, language, targetCurrency, clientName, customerFirstName, customerLastName, minAmount, maxAmount, availableAmount]);

  switch (status) {
    case SessionStatus.Live:
      return <div className={`session-wrapper ${clientName || ''}`}>{children}</div>;
    case SessionStatus.Invalid:
      return (
        <Title
          className="session-error-text"
          text={_t(
            'Session id is not provided',
            'ECOMMPAY.SESSION_ID_NOT_PROVIDED',
          )}
        />
      );
    default:
      return <Loader isAltLoader />;
  }
}

export default SessionProvider;
