import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '#/store/store';

export enum SessionStatus {
  None = 'none',
  Live = 'live',
  Invalid = 'invalid',
}

export interface ISessionState {
  sessionId: string;
  sourceCurrency: string,
  targetCurrency: string;
  sessionStatus: SessionStatus;
  clientName: string,
  customerFirstName: string,
  customerLastName: string,
  minAmount: number,
  maxAmount: number,
  availableAmount?: number,
}

export const initialStateSession: ISessionState = {
  sessionId: '',
  sourceCurrency: 'USD',
  targetCurrency: '',
  sessionStatus: SessionStatus.None,
  clientName: '',
  customerFirstName: '',
  customerLastName: '',
  minAmount: 0,
  maxAmount: 0,
  availableAmount: undefined,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialStateSession,
  reducers: {
    setSession(state, action: PayloadAction<string>) {
      state.sessionId = action.payload;
      state.sessionStatus = SessionStatus.Live;
    },
    setTargetCurrency(state, action: PayloadAction<string>) {
      state.targetCurrency = action.payload;
    },
    setSourceCurrency(state, action: PayloadAction<string>) {
      state.sourceCurrency = action.payload;
    },
    setSessionStatus(state, action: PayloadAction<SessionStatus>) {
      state.sessionStatus = action.payload;
    },
    setClientName(state, action: PayloadAction<string>) {
      state.clientName = action.payload;
    },
    setCustomerName(state, action: PayloadAction<{ firstName: string, lastName: string, }>) {
      state.customerFirstName = action.payload.firstName;
      state.customerLastName = action.payload.lastName;
    },
    setAmountRules(state, action: PayloadAction<{ min: number, max: number, availableAmount: undefined | number }>) {
      state.minAmount = action.payload.min;
      state.maxAmount = action.payload.max;
      state.availableAmount = action.payload.availableAmount;
    },
  },
});

export const {
  setSession,
  setSessionStatus,
  setTargetCurrency,
  setClientName,
  setCustomerName,
  setAmountRules,
  setSourceCurrency,
} = sessionSlice.actions;

export default sessionSlice.reducer;

const selectSessionState = (state: RootState): ISessionState => state.session;

export const selectSessionStatus: (state: RootState) => SessionStatus = createDraftSafeSelector(
  selectSessionState,
  (sessionState: ISessionState) => sessionState.sessionStatus,
);

export const selectTargetCurrency: (state: RootState) => string = createDraftSafeSelector(
  selectSessionState,
  (sessionState: ISessionState) => sessionState.targetCurrency,
);

export const selectSourceCurrency: (state: RootState) => string = createDraftSafeSelector(
  selectSessionState,
  (sessionState: ISessionState) => sessionState.sourceCurrency,
);

export const selectClientName: (state: RootState) => string = createDraftSafeSelector(
  selectSessionState,
  (sessionState: ISessionState) => sessionState.clientName,
);

export const selectCustomerName: (state: RootState) => { customerFirstName: string, customerLastName: string } = createDraftSafeSelector(
  selectSessionState,
  (sessionState: ISessionState) => ({
    customerFirstName: sessionState.customerFirstName,
    customerLastName: sessionState.customerLastName,
  }),
);

export const selectAmountRules: (state: RootState) => { min: number, max: number, availableAmount?: number } = createDraftSafeSelector(
  selectSessionState,
  (sessionState: ISessionState) => ({
    min: sessionState.minAmount,
    max: sessionState.maxAmount,
    availableAmount: sessionState.availableAmount,
  }),
);
